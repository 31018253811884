/* eslint-disable func-names */
// import "./lib/selectize";
// import ionRangeSlider from "ion-rangeslider";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

export default function controls() {
  (function navToggle() {
    const header = document.querySelector(".header");
    const btn = header.querySelector(".header__menu-toggle");
    const sidebarBtn = header.querySelector(".hero__sidebar");
    const closeOverlay = header.querySelector(".header__menu-overlay");
    // const menuList = document.querySelector('.mobile-menu-list');

    btn.addEventListener("click", function () {
      btn.classList.toggle("header__menu-toggle_active");
      header.classList.toggle("header__menu_active");
    });

    sidebarBtn.addEventListener("click", function () {
      btn.classList.toggle("header__menu-toggle_active");
      header.classList.toggle("header__menu_active");
    });

    closeOverlay.addEventListener("click", function () {
      btn.classList.remove("header__menu-toggle_active");
      header.classList.remove("header__menu_active");
    });

    // menuList.addEventListener('click', function(e) {
    //     if(e.target.classList.contains('mobile-menu-list-item')) {
    //         header.classList.toggle('menu-open')
    //     }

    // })
  })();

  (function mapInit() {
    const mapEl = document.querySelector("#map");
    if (!mapEl) return;

    const { center } = mapEl.dataset;
    let centerCoords = center || "57.16701385330166, 65.58563697172929";

    centerCoords = centerCoords.split(", ").reverse();

    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [57.16701385330166, 65.58563697172929],
          },
          properties: {
            title: "г. Тюмень",
            description: "Республики, 211, 3 этаж, офис 308",
          },
        },
      ],
    };

    console.log(centerCoords);

    mapboxgl.accessToken =
      "pk.eyJ1Ijoia2h1ZGF5a292IiwiYSI6ImNrdXRwOWdpYzFkaXkydnF2YWhjZnd5b3AifQ.Up5giq62K8q-1HYrNxZ00Q";
    const map = new mapboxgl.Map({
      container: "map", // container ID
      style: "mapbox://styles/mapbox/dark-v10?optimize=true", // style URL
      reverseGeocode: true,
      center: centerCoords, // starting position [lng, lat]
      zoom: 9, // starting zoom
    }).addControl(
      new MapboxLanguage({
        defaultLanguage: "ru",
      })
    );
/* eslint-disable */
    for (const { geometry, properties } of geojson.features) {
      // create a HTML element for each feature
      const el = document.createElement("div");
      el.className = "marker";
      el.style.width = "31px";
      el.style.height = "31px";
      el.style.background = "#FF6B03";
      el.style.borderRadius = "100%";
      el.style.cursor = "pointer";

      // make a marker for each feature and add it to the map
      new mapboxgl.Marker(el)
        .setLngLat(geometry.coordinates.reverse())
        .setPopup(
          new mapboxgl.Popup({ offset: 0 }) // add popups
            .setHTML(
              `<h3>${properties.title}</h3><p>${properties.description}</p>`
            )
        )
        .addTo(map);
    }

/* eslint-enable */
  })();

  (function orderWindow() {
    const orderDiv = document.querySelector(".order-popup");
    if (!orderDiv) return;
    const links = document.querySelectorAll('[data-popup]')
    const closeBtns = document.querySelectorAll('.order-popup_close')

    const overlay = orderDiv.querySelector('.order-popup__overlay');

    global.hideModal = () => {
      orderDiv.classList.add('hidden');
    }
    global.showModal = () => {
      orderDiv.classList.remove('hidden');
    }

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', () => {
        global.showModal();
      });
    }

    for (let i = 0; i < closeBtns.length; i++) {
      closeBtns[i].addEventListener('click', () => {
        global.hideModal();
      });
    }

    overlay.addEventListener('click', () => {
      global.hideModal();
    });
  })();

}
