import Pristine from "pristinejs"; // eslint-disable-line import/no-unresolved
import "./lib/jquery.maskedinput";
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm'; // eslint-disable-line import/no-unresolved
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm'; // eslint-disable-line import/no-unresolved

export default function modals() {

    (function photoSwipeGallery() {
      const lightbox = new PhotoSwipeLightbox({
        gallery: '.product-gallery__list',
        children: 'a',
        showHideAnimationType: 'zoom',
        pswpModule: PhotoSwipe
      });
      lightbox.init();
    })();

    // $('input[type="tel"]').mask("+7 (999) 999-99-99");

    (function formValidation() {
        const formList = document.querySelectorAll(".order-popup__form");

        formList.forEach((form) => {
          const validation = new Pristine(form);
          const successWrapper = form.querySelector('.success-text');
          form.addEventListener("submit", function (e) {
            // console.log('submit');
            e.preventDefault();
            const formData = new FormData(this);
            const valid = validation.validate();
            if(successWrapper) {
              successWrapper.classList.add('d-none');
            }
            if (valid) {
              $.ajax({
                // dataType: "json",
                type: form.method,
                url: form.action,
                data: formData,
                cache: false,
                processData: false,
                contentType: false,
                success(data) {
                    form.reset();
                    if(successWrapper) {
                      successWrapper.classList.remove('d-none');
                    }
                    setTimeout(() => {
                      global.hideModal();
                      successWrapper.classList.add('d-none');
                    }, 1500);
                    // wrapper.classList.remove('loader');
                },
                // error(request, status, error) {},
              });
            }
          });
        });
      })();

}
