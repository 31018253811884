// import { gsap } from 'gsap'
// import { ScrollScene } from 'scrollscene'
import { SmoothScroll } from "./lib/SmoothScroll";

export default function animation() {


    (function getMouseCordinate() {
      const block  = document.querySelector('.category-tile');
      if(!block) return;

      const cursor = block.querySelector('.category-tile__cursor');
      if(!cursor) return;

      block.addEventListener('mousemove', (event) => {
        const {clientX, clientY} = event;
        cursor.style.left = `${clientX  }px`;
        cursor.style.top = `${clientY  }px`;
      });

      block.addEventListener('mouseover', (event) => {
        const {clientX, clientY} = event;
        cursor.style.left = `${clientX  }px`;
        cursor.style.top = `${clientY  }px`;
      });

    })();

    // (function scrollToDiv() {
    //     const links = document.querySelectorAll('[data-scroll-to]');
    //     const header = document.querySelector('.header');
    //     if(!links) return;

    //     links.forEach((item) => {
    //         let direct = item.dataset.scrollTo;
    //         // console.log(direct);
    //         let directFix = `#${direct.split('#')[1]}`;
    //         // console.log(directFix);
    //         if(!document.querySelector(directFix)) return;

    //         setTimeout(function() {
    //             if(window.location.hash) {
    //                 gsap.to(window, 1, {scrollTo: {y: window.location.hash, offsetY: header.clientHeight}});
    //             }
    //         }, 500)

    //         item.addEventListener('click', function(e) {
    //             e.preventDefault();
    //             direct = this.dataset.scrollTo;
    //             directFix = `#${direct.split('#')[1]}`;
    //             // window.location.hash = directFix
    //             gsap.to(window, 1, {scrollTo: {y: directFix, offsetY: header.clientHeight}});

    //         })
    //     })

    // })();

}
