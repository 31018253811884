/* eslint-disable  */
import Swiper, { Pagination, Controller, Navigation } from "swiper";
/* eslint-enable  */

export default function sliders() {

  (function facilitiesSlider() {
    // Install modules
    Swiper.use([Pagination, Controller])
    const swiperContent = new Swiper(".facilities__content-slider", {
      slidesPerView: 1,
      watchOverflow: true,
      pagination: {
        el: '.facilities__dots',
        type: 'bullets',
        clickable: true
      },
    });

    const swiperImage = new Swiper(".facilities__image-slider", {
      slidesPerView: 1,
      watchOverflow: true,
    });

    swiperContent.controller.control = swiperImage;
    swiperImage.controller.control   = swiperContent;
  })();

  (function simpleSlider() {
    const blocks = document.querySelectorAll('.simple-slider__block');
    if(!blocks) return;

    blocks.forEach(item => {
      const prevArrow       = item.querySelector('.simple-slider__arrow-prev');
      const nextArrow       = item.querySelector('.simple-slider__arrow-next');
      const sliderSelector  = item.querySelector('.simple-slider');
      // Install modules
      Swiper.use([Navigation, Pagination]);

      const swiperSimple = new Swiper(sliderSelector, {
        slidesPerView: 4,
        spaceBetween: 32,
        watchOverflow: true,
        navigation: {
          prevEl: prevArrow,
          nextEl: nextArrow
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
          992: {
            slidesPerView: 4
          }
        }
      });
    });

    const services = new Swiper(".services-slider", {
      slidesPerView: 4,
      spaceBetween: 32,
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3
        },
        992: {
          slidesPerView: 4
        }
      }
    });

  })();


}
