import sliders from './sliders';
import modals from './modals';
import controls from './controls';
import animation from './animation';

import checkUserSystem from "./lib/checkUserSystem";

global.ww = window.innerWidth;
global.wh = window.innerHeight;

$(document).ready(() => {
    document.body.className += ' ready';
    global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    controls();
    sliders();
    modals();
    animation();


    const allResponsives = () => {
        global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        console.log('allResponsives!')
    };
    allResponsives();

    $(window).on('resize', function() {
        global.ww = window.innerWidth;
        global.wh = window.innerHeight;
        allResponsives();
    });
});
